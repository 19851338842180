<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/images/logo_letras.png" alt="logo" height="40" width="150" >
        </a>
        <div class="navbar-toggler">
            <div class="containerX">
                <label id="switch" class="switch">
                        <label for="slider_desk" class="visually-hidden">Cambiar idioma</label>
                        <input type="checkbox" name="Cambiar idioma" (click)="toggleLang()" id="slider_desk" [(ngModel)]="slider">
                        <span class="slider round"></span>
                    </label>
                <!-- <button id="switch" onclick="toggleTheme()">Switch</button> -->
            </div>
        </div>
        <button class="navbar-toggler" type="button" (click)="toggleClass()" aria-label="Abrir menu">
            
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ng-container *transloco="let t;">
            <ul class="navbar-nav ms-auto">
                <div class="containerX">
                    <label id="switch" class="switch">
                            <input type="checkbox" name="Cambiar idioma" (click)="toggleLang()" id="slider" [(ngModel)]="slider">
                            <span class="slider round"></span>
                        </label>
                    <!-- <button id="switch" onclick="toggleTheme()">Switch</button> -->
                </div>
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')" >{{ t('home') }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about-us')">MikroDash</span></li>
                <li class="nav-item" style="max-width: 200px; width: 200px;"><span class="nav-link" (click)="onClick('plataforma')" >{{ t('howto') }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')" >{{ t('contact') }}</span></li>
                
            </ul>
            <ul class="navbar-nav ms-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')" >{{ t('home') }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about-us')">MikroDash</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('plataforma')" >{{ t('howto') }}</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')" >{{ t('contact') }}</span></li>
            </ul>
            <div class="navbar-btn">
                <a href="https://app.mikrodash.com" target="_blank" >{{ t('tryplatform') }}</a>
            </div>
            </ng-container>
        </div>

        
    </div>
</nav>