import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  newForm(form: any){
    //console.log(form);
    return this.http.post<any>(`${environment.apiUrl}/new-form`, JSON.parse(JSON.stringify(form)));
  }
}
