<div class="fondo"></div>
<div class="container ptb-100">
  <ng-container *transloco="let t;">

    <h1>{{ t('privacidad_title') }}</h1>
    <h6>{{ t('privacidad_subtitle') }}</h6>
    <h2>{{ t('descripcion_plataforma_title') }}</h2>
    <p>{{ t('descripcion_plataforma_text') }}</p>
    <h2>{{ t('informacion_recopilada_title') }}</h2>
    <p>{{ t('informacion_recopilada_text') }}</p>
    <h2>{{ t('uso_informacion_title') }}</h2>
    <p>{{ t('uso_informacion_text') }}</p>
    <h2>{{ t('seguridad_title') }}</h2>
    <p>{{ t('seguridad_text') }}</p>
    <h2>{{ t('responsabilidad_title') }}</h2>
    <p>{{ t('responsabilidad_text') }}</p>
    <h2>{{ t('derechos_title') }}</h2>
    <p>{{ t('derechos_text') }} <a href="mailto:privacidad@mikrodash.com">privacidad@mikrodash.com</a>. {{ t('derechos_text2') }}</p>
    <h2>{{ t('retencion_title') }}</h2>
    <p>{{ t('retencion_text') }}</p>
    <h2>{{ t('cookies_title') }}</h2>
    <p>{{ t('cookies_text') }}</p>
    <h2>{{ t('enlaces_title') }}</h2>
    <p>{{ t('enlaces_text') }}</p>
    <h2>{{ t('notificaciones_title') }}</h2>
    <p>{{ t('notificaciones_text') }}</p>
    <h2>{{ t('pagos_title') }}</h2>
    <p>{{ t('pagos_text') }}
    <h2>{{ t('contacto_title') }}</h2>
    <p>{{ t('contacto_text') }}
      <a href="mailto:privacidad@mikrodash.com">privacidad@mikrodash.com</a>
  </ng-container>
</div>