import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-portfolio-contact',
  templateUrl: './portfolio-contact.component.html',
  styleUrls: ['./portfolio-contact.component.scss']
})
export class PortfolioContactComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;
  emailSent = false;
  emailError = false;

  constructor(private contactServ: ContactService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.contactForm = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        subject: ['', [Validators.required]],
        message: ['', [Validators.required]]
    });
  }

  get registerFormControl() {
    return this.contactForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    if (this.contactForm.valid) {
      this.contactServ.newForm({'to': this.contactForm.controls.email.value, 'subject': this.contactForm.controls.subject.value, 'message': this.contactForm.controls.name.value + " : " + this.contactForm.controls.message.value })
      .subscribe(res => {
        this.emailSent = true;
        console.log(res);
      }, err => {
        console.log(err)
        this.emailError = true;
      })
    }
  }
}
