<div id="plataforma" class="how-use pt-100">
    <div class="container">
        <ng-container *transloco="let t;">
        <div class="section-title">
            <span class="text-sub" >{{ t('howtostart') }}</span>
            <h2 >{{ t('tutorialsteps') }}</h2>
            <p class="text-sub" >{{ t('howtostartdesc') }}</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-one">
                            <span>1</span>
                            <i class="flaticon-settings"></i>
                            <h3 >{{ t('createAccount') }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p class="text-sub" ><a href="https://app.mikrodash.com" target="_blank">{{ t('createFreeAccount') }}</a> {{ t('createAccountInmikrodash') }}</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p class="text-sub" >{{ t('usea') }} <a href="https://github.com/MikroDash" target="_blank">{{ t('templateprojects') }}</a>{{ t('anduploaddevice') }}</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-two">
                            <span>2</span>
                            <i class="flaticon-coding"></i>
                            <h3 >{{ t('uploaddevice') }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-three">
                            <span>3</span>
                            <i class="icofont-responsive"></i>
                            <h3 >{{ t('customizedashboard') }}</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p class="text-sub" ><a href="https://app.mikrodash.com/dashboard/main" target="_blank">{{ t('customizeddescription') }}</a></p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p class="text-sub" >{{ t('readydesc') }}</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-four">
                            <span>4</span>
                            <i class="icofont-architecture-alt"></i>
                            <h3 >{{ t('controllandmonitor') }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-2 wow fadeInUp" data-wow-duration="1s">
                <div class="how-use-slider" *ngIf="!isLoading">
                    <owl-carousel-o [options]="howToUseSlider">
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <picture>
                                    <source srcset="assets/images/howtouse.webp" type="image/webp">
                                    <img src="assets/images/howtouse.png" alt="mikrodash_howtouse" height="600" width="296">
                                </picture>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        </ng-container>
    </div>
</div>