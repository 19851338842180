import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Meta,Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(private router: Router, private gtmService: GoogleTagManagerService, private meta: Meta,private title: Title) {
        this.meta.addTags([
            { name: 'robots', content: 'index, follow' },
            { name: 'description', content: 'Conecta tus ideas con el mundo -> Todos tenemos ideas, pero, ¿estas dispuesto a hacerlas realidad?Hazlo con MikroDash, una forma fácil, gratuita para validar y conectar tus ideas con el Internet.'},
            { name: 'author', content: 'Diego Careaga / diegocareaga16'},
            { name: 'keywords', content: "MikroDash, MikroDash App, MikroDash Platform, microdash, Plataforma IoT gratuita, MikroTutoriales, MQTT, Broker gratis, Broker MQTT, diegocareaga16, Diego Careaga, IoT, Internet de las cosas, Internet of things, Industria 4.0, Smart Factory, Platform IoT, Alexa con Arduino, ESP32, ESP8266, Raspberry, Web Secure Sockets"},
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { charset: 'UTF-8' }
          ]);
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };

                this.gtmService.pushTag(gtmTag);
            }
        });
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}