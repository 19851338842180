<app-portfolio-banner></app-portfolio-banner>

<about-us></about-us>

<portafolio-how-to-use></portafolio-how-to-use>

<!-- <app-portfolio-about></app-portfolio-about> -->

<!-- <app-portfolio-services></app-portfolio-services>

<app-portfolio-software></app-portfolio-software>

<app-portfolio-showcase></app-portfolio-showcase>

<app-portfolio-partner></app-portfolio-partner>

<app-portfolio-testimonials></app-portfolio-testimonials> -->

<app-portfolio-contact></app-portfolio-contact>