<div id="home" class="main-banner banner-style-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <ng-container *transloco="let t;">
                                <h1 >{{ t('connectyourideas') }}</h1>
                                <h6 >{{ t('iotplatform') }}</h6>
                                <p >{{ t('everyonehasideas') }} <br> {{ t('easywayto') }}</p>
                                <div class="banner-btn">
                                    <a href="https://app.mikrodash.com" target="_blank" >{{ t('tryplatform') }}</a>
                                    <a href="https://github.com/MikroDash" target="_blank" class="cv-btn" >{{ t('examplesgit') }}</a>     
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <div class="banner-img-two" *ngIf="!isLoading">
                            <img src="assets/gif/iot_lamp.gif" alt="iphone">
                            <!-- Some Place in our DOM -->
                            <!-- <img src="launcher.gif"> -->
                            <!-- <video autoplay loop muted playsinline height="300" width="100%">
                                <source src="assets/images/iot_lamp.webm" type="video/webm">
                                <source src="assets/images/iot_lamp.mp4" type="video/mp4">
                            </video> -->
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
    </div>
</div>