import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, public ngxSmartModalService: NgxSmartModalService, private router: Router) {}

    public onClick(elementId: string): void { 
        if("/privacidad" === this.router.url){
            this.router.navigateByUrl('/');
        }else{
            this.viewportScroller.scrollToAnchor(elementId);
        }
    }

    ngOnInit() {
    }

}