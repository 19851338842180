import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    slider: boolean = false;
    route: string;

    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    constructor(private viewportScroller: ViewportScroller, private router: Router, private _translocoService: TranslocoService, private titleService: Title) {
        if(window.location.href != ''){
            this.route = window.location.href;
          } else {
            this.route = 'es'
          }
    }

    public onClick(elementId: string): void { 
        if("/privacidad" === this.router.url){
            this.router.navigateByUrl('/');
        }else{
            this.viewportScroller.scrollToAnchor(elementId);
        }
    }

    ngOnInit() {

        if(this.route.includes("es")){
            this._translocoService.setActiveLang("es");
        }else if(this.route.includes("en")){
            this._translocoService.setActiveLang("en");
        }else{
            this._translocoService.setActiveLang("es");
        }
        if(this.route.includes("home") || this.route.includes("inicio")){
            this.titleService.setTitle('Inicio MikroDash');
            this.viewportScroller.scrollToAnchor('home');
        }else if(this.route.includes("about-us") || this.route.includes("nosotros") || this.route.includes("about")){
            this.titleService.setTitle('¿Qué es MikroDash?');
            this.viewportScroller.scrollToAnchor('about-us');
        }else if(this.route.includes("plataforma") || this.route.includes("plataform") || this.route.includes("app")){
            this.titleService.setTitle('Plataforma IoT MikroDash');
            this.viewportScroller.scrollToAnchor('plataforma');
        }else if(this.route.includes("contact") || this.route.includes("contacto")){
            this.titleService.setTitle('Contacto MikroDash');
            this.viewportScroller.scrollToAnchor('contact');
        }
        
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;
            if (activeLang === "en") {
                this.slider = true;
            }else{
                this.slider = false;
            }
        });
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    toggleLang(){
        if(this.slider){
            // Set the active lang
            this._translocoService.setActiveLang("es");
        }else{
            // Set the active lang
            this._translocoService.setActiveLang("en");
        }
    }


}