<div id="about-us" class="standard-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-img" *ngIf="!isLoading">
                    <img src="assets/gif/iot-animate.gif" alt="iphone">
                    <!-- <video autoplay loop muted playsinline height="300" width="100%">
                        <source src="assets/images/iot-animate.webm" type="video/webm">
                        <source src="assets/images/iot-animate.mp4" type="video/mp4">
                    </video> -->
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-text">
                    <ng-container *transloco="let t;">
                        <div class="section-title text-start">
                            <span >{{ t('whatis') }}</span>
                            <h2 >{{ t('short-description') }}</h2>
                        </div>
                        <p >{{ t('description-1') }}</p>
                        <p >{{ t('description-2') }}</p>
                        <p >{{ t('description-3') }}</p>
                        <div class="standard-btn">
                            <a href="https://app.mikrodash.com" target="_blank" >{{ t('tryplatform') }}</a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>