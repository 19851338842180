<div id="contact" class="pb-100">
    <div id="contact" class="contact-section ptb-100">
        <div class="container">
            <ng-container *transloco="let t;">
            <div class="section-title">
                <h2 >{{ t('doyouliketalk') }}</h2>
                <span >{{ t('letusknow') }}</span>
            </div>
            <div class="row">
                <div class="col-lg-5">
                    <div class="banner-img-two">
                        <picture>
                            <source srcset="assets/images/contact-us.webp" type="image/webp">
                            <img src="assets/images/contact-us.png" alt="mikrodash_contact" height="376" width="376">
                        </picture>
                    </div>
                </div>
                <div class="col-lg-6 offset-lg-1">
                    <div class="contact-form">
                        <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-12 col-sm-6">
                                    <div class="form-group mb-3">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="{{ t('inputname') }}" formControlName="name">
                                        <span class="text-danger"
                                            *ngIf="(registerFormControl.name.touched || submitted) && registerFormControl.name.errors?.required" >
                                            {{ t('pleaseinsertname') }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-6">
                                    <div class="form-group mb-3">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="{{ t('inputemail') }}" formControlName="email" >
                                        <span class="text-danger"
                                            *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                                            {{ t('pleaseinsertemail') }}
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.email" >
                                            {{ t('formatemailicorrect') }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="{{ t('inputsubject') }}" formControlName="subject">
                                        <span class="text-danger"
                                            *ngIf="(registerFormControl.subject.touched || submitted) && registerFormControl.subject.errors?.required" >
                                            {{ t('pleaseinsertsubject') }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="{{ t('inputmessage') }}" formControlName="message"></textarea>
                                        <span class="text-danger"
                                            *ngIf="(registerFormControl.message.touched || submitted) && registerFormControl.message.errors?.required" >
                                            {{ t('pleaseinsertmessage') }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    
                                    <button [disabled]="!contactForm.valid" type="submit" class="default-btn contact-btn" *ngIf="emailSent == false" >{{ t('sendemail') }}<span *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> 
                                    
                                    <span class="text-danger" *ngIf="!contactForm.valid" >
                                        {{ t('pleaseinsertdata') }}
                                    </span>
                                    <span class="text-success" *ngIf="emailSent == true" >
                                        {{ t('wesendemail') }}
                                    </span>
                                    <span class="text-danger" *ngIf="emailError == true" >
                                        {{ t('sendemailerror') }}
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>