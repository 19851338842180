import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  isLoading = true;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
		this.isLoading = false;
	}

}
