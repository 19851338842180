import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'portafolio-how-to-use',
    templateUrl: './portafolio-how-to-use.component.html',
    styleUrls: ['./portafolio-how-to-use.component.scss']
})
export class PortafolioHowToUseComponent implements OnInit {

	isLoading = true;

    constructor() { }

    ngOnInit(): void {
    }

	ngAfterContentInit() {
		this.isLoading = false;
	}

    howToUseSlider: OwlOptions = {
		loop:true,
		margin:10,
		nav:false,
		dots:true,
		center:true,
		smartSpeed:2000,
		responsive:{
			0:{
				items:1
			},
			600:{
				items:3
			},
			992:{
				items:1
			}
		}
    }

}