import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StickyNavModule } from 'ng2-sticky-nav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PortfolioLandingComponent } from './components/pages/portfolio-landing/portfolio-landing.component';

import { PortfolioBannerComponent } from './components/pages/portfolio-landing/portfolio-banner/portfolio-banner.component';
import { PortfolioServicesComponent } from './components/pages/portfolio-landing/portfolio-services/portfolio-services.component';
import { PortfolioSoftwareComponent } from './components/pages/portfolio-landing/portfolio-software/portfolio-software.component';
import { PortfolioShowcaseComponent } from './components/pages/portfolio-landing/portfolio-showcase/portfolio-showcase.component';
import { PortfolioPartnerComponent } from './components/pages/portfolio-landing/portfolio-partner/portfolio-partner.component';
import { PortfolioContactComponent } from './components/pages/portfolio-landing/portfolio-contact/portfolio-contact.component';
import { PortfolioTestimonialsComponent } from './components/pages/portfolio-landing/portfolio-testimonials/portfolio-testimonials.component';
import { PortafolioHowToUseComponent } from './components/pages/portfolio-landing/portafolio-how-to-use/portafolio-how-to-use.component';
import { AboutUsComponent } from './components/pages/portfolio-landing/portafolio-about-us/about-us.component';

import { ReactiveFormsModule } from '@angular/forms';
import { PrivacidadComponent } from './components/pages/portfolio-landing/privacidad/privacidad.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { TranslocoRootModule } from './services/transloco/transloco-root.module';


@NgModule({
    declarations: [
        AppComponent,
        AboutUsComponent,
        PortfolioLandingComponent,
        PortfolioBannerComponent,
        PortfolioServicesComponent,
        PortfolioSoftwareComponent,
        PortfolioShowcaseComponent,
        PortfolioPartnerComponent,
        PortfolioContactComponent,
        PortfolioTestimonialsComponent,
        PortafolioHowToUseComponent,
        PrivacidadComponent,
        NavbarComponent,
        FooterComponent,
    ],
    imports: [
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        StickyNavModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxSmartModalModule.forRoot(),
        TabsModule,
        NgxScrollTopModule,
        GoogleTagManagerModule.forRoot({
            id: "GTM-KZL6WRP",
          }),
        TranslocoRootModule
    ],
    providers: [HttpClientModule],
    bootstrap: [AppComponent]
})
export class AppModule { }