<footer class="footer-area">
    <ng-container *transloco="let t;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/images/logo_letras.png" alt="logo" height="78" width="300">
                    </div>
                    <p>{{ t('socialmedia') }}</p>
                    <div class="footer-link">
                        <a href="https://www.facebook.com/mikrodash" name="MikroDash Facebook" aria-label="MikroDash Facebook" target="_blank" class="text-icon"><i class="flaticon-facebook"></i></a>
                        <a href="https://twitter.com/mikro_dash" name="MikroDash Twitter" aria-label="MikroDash Twitter" target="_blank" class="text-icon"><i class="flaticon-twitter"></i></a>
                        <a href="https://www.instagram.com/mikrodash/" name="MikroDash Instagram" aria-label="MikroDash Instagram" target="_blank" class="text-icon"><i class="flaticon-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/mikrodash/" name="MikroDash LinkedIn" aria-label="MikroDash LinkedIn" target="_blank" class="text-icon"><i class="flaticon-linkedin"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3 >{{ t('contactus') }}</h3>
                    <ul>
                        <li><a href="mailto:info@mikrodash.com?Subject=Información%20Plataforma%20MikroDash" name="info@mikrodash.com" aria-label="info@mikrodash.com" target="_blank">info@mikrodash.com</a></li>
                        <li><a href="mailto:contact@mikrodash.com?Subject=Información%20Plataforma%20MikroDash" name="contact@mikrodash.com" aria-label="contact@mikrodash.com" target="_blank">contact@mikrodash.com</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3 >{{ t('shortcuts') }}</h3>
                    <ul>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('home')" >{{ t('home') }}</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('about-us')">MikroDash</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('plataforma')" >{{ t('howto') }}</span></li>
                        <li class="nav-item"><span class="nav-link" (click)="onClick('contact')" >{{ t('contact') }}</span></li>
                        <li class="nav-item"><span class="nav-link" [routerLink]="['/privacidad']" >{{ t('privacidad_title') }}</span></li>
                    </ul>
                </div>
            </div>
            <!-- Button trigger modal -->

        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <p >&copy;Copyright 2022 MikroDash. Todos los derechos reservados.</p>
                </div>
            </div>
        </div>
    </div>
    </ng-container>
</footer>