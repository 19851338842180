import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortfolioLandingComponent } from './components/pages/portfolio-landing/portfolio-landing.component';
import { PrivacidadComponent } from './components/pages/portfolio-landing/privacidad/privacidad.component';

const routes: Routes = [
    {path: '', component: PortfolioLandingComponent},
    
    {path: 'privacidad', component: PrivacidadComponent},
    {path: ':/privacidad', component: PrivacidadComponent},
    {path: ':/privacy', component: PrivacidadComponent},
    { path: ':idioma?/privacidad/:detalle?', component: PrivacidadComponent },
    { path: ':idioma?/privacy/:detalle?', component: PrivacidadComponent },

    // {path: 'sass-landing-two', component: SassLandingTwoComponent},
    // {path: 'app-landing', component: AppLandingComponent},
    // {path: 'agency-landing-one', component: AgencyLandingOneComponent},
    // {path: 'agency-landing-two', component: AgencyLandingTwoComponent},
    // {path: 'portfolio-landing', component: SassLandingOneComponent}
    {path: '**', component: PortfolioLandingComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }